export const TITLE = "Their News"
export const POWERED_BY = "powered by "
export const API_NAME = "</newscatcher>"
export const CTA = "Pick a political bias"


/* popupMessages content */
export const NOTHING_FOUND_TITLE = "No articles were found"
export const NOTHING_FOUND_BODY = "Try search for something else or changing the bias slider."

export const LOADING_TITLE = "Getting you news"
export const LOADING_BODY = ""
